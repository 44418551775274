.tiny-btn {
    background-color: rgb(20, 13, 14);
    display: flex;
    align-items: center;
    justify-content: center;
    float: "right";
    height: "1.5em";
}

.custom-table-container {
    max-height: 70vh;
    overflow: hidden;
}

.custom-table-header {
    height: 50px;
    overflow: hidden;
}

.custom-table-body {
    max-height: 65vh;
    overflow-y: auto;
}

.weight {
    margin-left: 0.5em;
}

.weight-details {
    color: #a12;
}