.center-text {
        text-align: center;
        margin: auto,
}

#vendor-btn {
    background-color: #a12;
    height: 40px;
    margin: auto;
    margin-top: 1em;
}