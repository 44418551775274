.input-styling {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    width: 100%;
}

.parent-div {
    display: inline-block;
    width: 50%;
    vertical-align: top;
}

.input-wrapper {
    margin-bottom: 0.5em;
}

.input-wrapper>* {
    display: block;
    width: 100%;
}