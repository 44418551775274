.input-styling {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    width: 100%;
    vertical-align: middle;
    /* Aligns the input vertically */
}

.left-indent {
    margin-left: 0.5em;
}

.split-parent {
    width: 50%
}

.top-row {
    margin-top: 0.5em;
}

.parent-div {
    display: flex;
    width: 100%;
}

.carton-pallet-details {
    position: absolute;
    right: 1em;
    bottom: 1.75em;
}

.button-input-container {
    display: flex;
    align-items: center;
    /* Vertically aligns the contents */
    gap: 0.5em;
    /* Adds a small space between the button group and input */
}

.centered-div {
    height: 80vh;
    width: 80vh;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    background-color: #ccd;
    outline: solid 1px black;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .centered-div h4 {
    color: black;
    margin-bottom: 10px;
  }

  .centered-div input {
    width: 100%;
    max-width: 300px;
    padding: 10px;
  }

  .centered-div .buttons {
    display: flex;
    justify-content: center;
  }

  .centered-div .buttons button {
    margin-top: 0.5em;
    margin-right: 1.25em;
    margin-left: 1.25em;
}