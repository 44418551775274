/* Style for the modal container */
.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
}

/* Style for the modal content */
.modal-content {
  max-width: 80%;
  max-height: 80%;
  overflow: auto;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Style for the modal heading */
.modal h2 {
  font-size: 24px;
  line-height: 1.5;
  margin: 10px;
  text-align: center;
}

/* Style for the modal button */
.modal button {
  margin: 20px 0;
}

.contact_top {
  padding-top: 0.5em;
}

.contact_info {
  padding-left: 0.5em;
}