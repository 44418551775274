.parent-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.modal-container {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 50%;
    transform: translate(-50%, -50%);
    background-color: #a12;
    color: white;
    height: 50vh;
}

.modal-container h1 {
    margin-top: 5%;
    margin-bottom: 5%;
    text-align: center;
}

.modal-container h3 {
    margin-top: 10%;
    text-align: center;
}

.modal-container button {
    /* display: block; */
    margin: 2em;
    width: 10em;
}